import { getChallengeSlugFromLocation } from '../Location/locationProviderPropsMap';
import { handleError } from '../ErrorHandler/errorHandlerPropsMap';
import { getMockedChallenge } from '../main/getMockedChallenges';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { request } from '../../services/request';

import {
  challenge,
  ChallengeServerlessData,
} from '../../api/challenges/challenge.api';
import {
  listSections,
  listSteps,
} from '@wix/ambassador-challenges-v1-challenge/http';
import {
  ChallengeSection,
  ChallengeStep,
  DurationUnit,
} from '@wix/ambassador-challenges-v1-challenge/types';
import { isAvailableForJoinImmediately } from '../../selectors/isAvailableForJoinImmediately';
import { Referrer } from '../storage/referrer';
import { getFromMemoryStorage } from '../storage/storage';

export interface IChallengeDataProps {
  isDescriptionShown: boolean;
  challengeData: ChallengeServerlessData & {
    isAvailableForJoinImmediately: boolean;
  };
  challengeSections?: ChallengeSection[];
  challengeSteps?: ChallengeStep[];
  requestChallengeSections?: Function;
  requestChallengeSteps?: Function;
  isMyWalletInstalled: boolean;
  isProfileInstalled: boolean;
  profileUrl: string;
}

export const shouldReportErrorMonitor = (err) => err?.httpStatus !== 403;

export async function handleUserLogin(flowAPI: ControllerFlowAPI) {
  flowAPI.controllerConfig.wixCodeApi.user.onLogin(async () => {
    const challengeId = getChallengeSlugFromLocation(flowAPI);

    let challengeResponse: ChallengeServerlessData = {
      challenge: null,
    };

    try {
      if (challengeId) {
        challengeResponse = await requestChallenge(challengeId, flowAPI);
      }
    } catch (error) {
      handleError({
        error,
        context: 'onUserLogin.getChallenge',
        preventErrorMonitorReport: !shouldReportErrorMonitor(error),
      });
    }

    flowAPI.controllerConfig.setProps({
      challengeData: {
        ...challengeResponse,
        ...{
          isAvailableForJoinImmediately: isAvailableForJoinImmediately(
            challengeResponse.challenge,
          ),
        },
      },
    });
  });
}

export async function requestChallenge(
  slugOrChallengeId: string,
  flowAPI: ControllerFlowAPI,
  referrer?: Referrer,
): Promise<ChallengeServerlessData> {
  const mockedChallenge = getMockedChallenge(slugOrChallengeId, flowAPI);
  if (mockedChallenge) {
    return { challenge: mockedChallenge, badgesData: { badges: [] } };
  }

  if (!slugOrChallengeId) {
    return;
  }

  const serverlessResp = await getFromMemoryStorage(flowAPI.controllerConfig, {
    key: `requestChallenge:${slugOrChallengeId}`,
    getter: async () => request(flowAPI, challenge(slugOrChallengeId)),
    referrer,
  });

  if (serverlessResp.data.errorMessage) {
    console.error(serverlessResp.data.errorMessage);
  }

  return {
    challenge: serverlessResp.data.challenge,
    badgesData: serverlessResp.data.badgesData,
    groupData: serverlessResp.data.groupData,
    instructorsData: serverlessResp.data.instructorsData,
  };
}

export async function requestChallengeSections(
  slugOrChallengeId: string,
  flowAPI: ControllerFlowAPI,
): Promise<ChallengeSection[]> {
  const mockedChallenge = getMockedChallenge(slugOrChallengeId, flowAPI);

  if (!slugOrChallengeId) {
    return null;
  } else if (mockedChallenge) {
    return [];
  }

  return (
    await request(
      flowAPI,
      listSections({
        challengeId: slugOrChallengeId,
      }),
    )
  )?.data?.sections;
}

export async function requestChallengeSteps(
  slugOrChallengeId: string,
  flowAPI: ControllerFlowAPI,
): Promise<ChallengeStep[]> {
  const mockedChallenge = getMockedChallenge(slugOrChallengeId, flowAPI);

  if (!slugOrChallengeId) {
    return null;
  } else if (mockedChallenge) {
    return [];
  }

  return (
    await request(
      flowAPI,
      listSteps({
        challengeId: slugOrChallengeId,
        fromOffset: {
          unit: DurationUnit.DAYS,
          value: 0,
        },
        toOffset: {
          unit: DurationUnit.DAYS,
          value: 1000,
        },
      }),
    )
  )?.data?.steps;
}
