import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { IParticipantSectionsContext } from './ParticipantSectionsContext';
import { userProviderPropsMap } from '../User/userProviderPropsMap';
import userTypeHandlers from '../User/helpers/userTypeHandlers';
import {
  ChallengeSection,
  State as ParticipantState,
} from '@wix/ambassador-challenges-v1-participant/types';
import { isMockedChallenge } from '../main/getMockedChallenges';
import { handleError } from '../ErrorHandler/errorHandlerPropsMap';
import { isForcedPreviewParticipant } from '../../selectors/isForcedPreview';
import { challengesListDataProviderPropsMap } from '../ChallengesListDataProvider/challengesListDataProviderPropsMap';
import { toParticipantSections } from './toParticipantSections';
import { getUrlParams } from '../Location/locationProviderPropsMap';
import { requestChallenge } from '../ChallengeDataProvider/helpers';
import { listSections } from '@wix/ambassador-challenges-v1-challenge/http';
import { Challenge } from '@wix/ambassador-challenges-v1-challenge/types';
import {
  listSections as listParticipantSections,
  myProgramSection,
} from '@wix/ambassador-challenges-v1-participant/http';
import { request } from '../../services/request';
import { patchParticipantSectionList } from '@wix/challenges-web-library';

export const loadParticipantSections = async (
  flowAPI: ControllerFlowAPI,
  program: Challenge,
): Promise<IParticipantSectionsContext['listParticipantSections']> => {
  const { isEditor, isPreview } = flowAPI.environment;
  const userProvider = await userProviderPropsMap(flowAPI);
  const { participant } = userProvider;
  const isJoinedParticipant =
    participant?.id &&
    userTypeHandlers.isJoinedAlready(participant?.transitions?.[0]?.state);
  const challengeId = program?.id;
  let sections: ChallengeSection[] = [];

  if (isMockedChallenge(challengeId, flowAPI)) {
    return [];
  }

  flowAPI.controllerConfig.setProps({
    isListParticipantSectionsRequestInProgress: true,
  });

  if (
    isJoinedParticipant &&
    userProvider.userType !== ParticipantState.RUNNING &&
    program?.stepsSummary?.sectionsNumber > 0
  ) {
    try {
      sections = (
        await request(
          flowAPI,
          listParticipantSections({
            challengeId,
            participantId: participant?.id,
          }),
        )
      )?.data?.sections;
    } catch (error) {
      handleError({ error, context: 'loadSections' });
    }
  }

  if (
    (isEditor ||
      isPreview ||
      isForcedPreviewParticipant(
        flowAPI?.controllerConfig?.wixCodeApi?.location?.query,
      )) &&
    !sections?.length
  ) {
    const challengeIdForPreview =
      challengeId ||
      (await challengesListDataProviderPropsMap(flowAPI)).challengesListData
        ?.memberChallenges?.[0]?.challenge?.id;
    if (challengeIdForPreview) {
      try {
        const ownerSections = (
          await request(
            flowAPI,
            listSections({
              challengeId: challengeIdForPreview,
            }),
          )
        )?.data?.sections;
        sections = toParticipantSections(ownerSections);
      } catch (error) {
        handleError({
          error,
          context: isEditor
            ? 'loadParticipantSections[editor]'
            : 'loadParticipantSections[preview]',
        });
      }
    }
  }

  flowAPI.controllerConfig.setProps({
    isListParticipantSectionsRequestInProgress: false,
  });

  return patchParticipantSectionList(sections);
};

export async function getSectionNavigationInfo(
  flowAPI,
  sections: ChallengeSection[],
): Promise<ChallengeSection | undefined> {
  const urlParams = getUrlParams(flowAPI);
  if (urlParams.navigationType === 'section') {
    const originalSection = sections.find(
      (s) => s.id === urlParams.navigationId,
    );
    if (originalSection) {
      return originalSection;
    }
    const challenge = await requestChallenge(urlParams.slug, flowAPI);
    return (
      await request(
        flowAPI,
        myProgramSection({
          programSectionId: urlParams.navigationId,
          programId: challenge.challenge.id,
        }),
      )
    )?.data?.participantSection;
  }
}
