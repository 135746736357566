import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { IUserContext } from '../UserContext';
import { paidPlansPropsMap } from '../../PaidPlans/paidPlansPropsMap';
import { leaveProgram } from './leaveProgram';

export async function leaveTheChallenge(
  flowAPI: ControllerFlowAPI,
  participantId: string,
  userProvider: IUserContext,
  challengeId: string,
) {
  await leaveProgram(flowAPI, participantId, challengeId);

  await userProvider.updateParticipant();
  // because of PPs doesn't load when member is joined we need to trigger load of PPs on leave challenge
  await paidPlansPropsMap(flowAPI);

  flowAPI.controllerConfig.setProps({
    participantSteps: [],
  });
}
